import { ApplicationRef, Injectable } from '@angular/core';
import { HttpBackendService } from '@memberspot/frontend/shared/data-access/common';
import { clearSentryUserInfo } from '@memberspot/frontend/shared/util/sentry';
import { SetLanguageDto } from '@memberspot/models';
import { PreviewTokenReturnData } from '@memberspot/models';
import { RecursivePartial } from '@memberspot/models';
import {
  AdminUser,
  AdminUserEndpoints,
  ProfileSetting,
  ProfileSettings,
} from '@memberspot/shared/model/admin-user';
import { Language } from '@memberspot/shared/model/types';
import {
  CollectionConfig,
  FireAuthService,
  getCustomClaims,
} from 'akita-ng-fire';
import { User } from 'firebase/auth';
import { doc, setDoc } from 'firebase/firestore';
import { concat, interval, Observable } from 'rxjs';
import { filter, first, tap } from 'rxjs/operators';

import { AuthState, AuthStore } from './auth.store';

@Injectable({ providedIn: 'root' })
@CollectionConfig({ path: 'users' })
export class AuthService extends FireAuthService<AuthState> {
  isActive = true;

  get uid() {
    return this.store.getValue().uid;
  }

  constructor(
    protected store: AuthStore,
    private appRef: ApplicationRef,
    private http: HttpBackendService,
  ) {
    super(store);
    this.setUpBlurEvent();
  }

  setLoading(loading = true) {
    this.store.setLoading(loading);
  }

  createProfile(user: User): AuthState['profile'] {
    return {
      id: user.uid,
      email: user.email || '',
      name: user.displayName || '',
      picture: user.photoURL || '',
      language: Language.De,
    };
  }

  setQuestionStatus() {
    this.store.update((state) => ({
      ...state,
      profile: {
        ...state.profile,
        leadQualified: true,
      },
    }));
  }

  getDemoUserToken(schoolId: string): Observable<PreviewTokenReturnData> {
    return this.http.get<PreviewTokenReturnData>(
      AdminUserEndpoints.getDemoUserAuthToken(schoolId),
    );
  }

  getUserToken(
    schoolId: string,
    uid: string,
  ): Observable<PreviewTokenReturnData> {
    return this.http.generic<PreviewTokenReturnData>(
      AdminUserEndpoints.GET_USER_TOKEN(schoolId, uid),
    );
  }

  resendVerificationMail(email: string) {
    return this.http.generic(AdminUserEndpoints.VERIFY_EMAIL(email));
  }

  selectRoles(user: User): Promise<AuthState['roles']> {
    // Fetch keys "superAdmin" of the claims in the token
    return getCustomClaims(user, ['superAdmin']) as any;
  }

  setUpBlurEvent() {
    window.addEventListener('focus', () => {
      this.isActive = true;
    });
    window.addEventListener('blur', () => {
      this.isActive = false;
    });
  }

  async refreshToken() {
    const user = this.auth.currentUser;

    if (!user) {
      return;
    }

    await user.getIdToken(true);

    const roles = await this.selectRoles(user);

    this.store.update({
      roles,
    });
  }

  async signOut() {
    await this.auth.signOut();
    clearSentryUserInfo();
  }

  setupAliveMessage(uid: string) {
    const appIsStable$ = this.appRef.isStable.pipe(
      first((isStable) => isStable === true),
    );
    const every15Min$ = interval(15 * 60 * 1000);
    const everySixHoursOnceAppIsStable$ = concat(appIsStable$, every15Min$);

    return every15Min$.pipe(
      filter((res) => this.isActive),
      tap(() => this.setAliveMessage(uid)),
    );
  }

  setAliveMessage(uid: string) {
    if (uid) {
      setDoc(
        doc(this.db, `users/${uid}`),
        { active: new Date() },
        { merge: true },
      );
    }
  }

  updateDialogSetting(setting: ProfileSetting, value: boolean | 0) {
    if (!setting) {
      return Promise.resolve();
    }

    return this.udpateSettings({ [setting]: value });
  }

  udpateSettings(upt: Partial<ProfileSettings>) {
    return this.updateProfile({ settings: upt });
  }

  updateProfile(upt: RecursivePartial<AdminUser>) {
    return setDoc(doc(this.db, `users/${this.uid}`), upt, { merge: true });
  }

  updateUserLanguage(language: Language) {
    const dto: SetLanguageDto = {
      language: language,
    };

    return this.http.generic(AdminUserEndpoints.UPDATE_LANGUAGE(dto));
  }
}
