import * as Sentry from '@sentry/angular-ivy';

export const setSentryUserInfo = (uid: string) => {
  if (!uid) {
    return;
  }

  Sentry.setUser({ id: uid });
};

export const clearSentryUserInfo = () => {
  Sentry.setUser(null);
};
